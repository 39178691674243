<template>
  <div class="container-scroller bg-cool">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="brand-logo">
              <img
                  src="./../../assets/images/imgs/logo-fondo-marino.png"
                  alt="logo"
                  class="d-block"
              />
            </div>
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <h4 class="font-weight-bold-blue">Bienvenido</h4>
              <h6 class="font-weight-light">Ingrese sus credenciales.</h6>
              <form @submit.prevent="login" class="pt-3"  autocomplete="off">
                <div class="form-group mb-3">
                  <input
                    type="text"
                    v-model="formData.email"
                    class="form-control form-control input-coolers"
                    id="exampleInputEmail1"
                    placeholder="email" autocomplete="off"
                  />
                </div>
                <div class="form-group mb-3">
                  <input
                    type="password"
                    v-model="formData.clave"
                    class="form-control form-control input-coolers"
                    id="exampleInputPassword1"
                    placeholder="Password"  autocomplete="off"
                  />
                </div>
                <p class="text-danger text-center" v-if="childMessage!=''">
                  {{ childMessage }}
                </p>
                <div class="mb-3">
                  <button
                    class="btn btn-block btn-primary btn font-weight-medium auth-form-btn"
                    type="submit"
                  >
                    Iniciar Sesión
                  </button>
                </div>
                <LoginSso @send-to-parent="receiveData" />
                <div
                  class=" text-center"
                >
                    <div class="form-check"></div>
                    <a href="#" class="text-black font-weight-light">¿Olvidó su contraseña?</a>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>
<script>
import Cookies from 'js-cookie';
import LoginSso from './components/LoginSso.vue';
export default {
  name: "Home",
  components: {
    LoginSso
  },
  data() {
    return {
      formData: {},
      error: false,
      cookieName: 'origin',
      cookieValue: process.env.VUE_APP_ORIGIN_COOKIE_MAIN,
      childMessage: ''
    };
  },
  methods: {
    receiveData(message) {
      this.childMessage = message;     
    },
    async login() {
      this.error = false;
      try {
        const resp = await this.$store.dispatch("auth/login", this.formData);      
        const { error,message } = resp;
        if (error) {
          this.childMessage = message;
          this.error = true;
          this.$router.push({ name: "Login" });
          return;
        }

        if (resp?.data?.result?.otp && resp?.data?.result?.email) {
          window.sessionStorage.setItem("email", resp?.data?.result?.email);
          this.$router.push("/otp");
        }
        
      } catch (error) {
        console.log(error);
      }
    },
    setCookie() {
      const reloadCookies = [
        process.env.VUE_APP_ORIGIN_COOKIE_CONOCEATUTECNICO_DEV,
        process.env.VUE_APP_ORIGIN_COOKIE_CONOCEATUTECNICO_PROD,
        process.env.VUE_APP_ORIGIN_COOKIE_CONOCEATUTECNICO_QA,
        process.env.VUE_APP_ORIGIN_COOKIE_CULTURAFRIA_DEV,
        process.env.VUE_APP_ORIGIN_COOKIE_CULTURAFRIA_PROD,
        process.env.VUE_APP_ORIGIN_COOKIE_CULTURAFRIA_QA,
      ]
      if(!Cookies.get(this.cookieName)){
        Cookies.set(this.cookieName, this.cookieValue,{secure:true});
      }else if(reloadCookies.includes(Cookies.get(this.cookieName))){
        location.reload();
      }
    }
  },
  computed: {
    cambiarClave() {
      return this.$store.getters["auth/cambiarClave"]
        ? this.$store.getters["auth/cambiarClave"]
        : null;
    },
  },mounted() {
    this.setCookie();
  }
};
</script>
